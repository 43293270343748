<template>
    <section>
        <div class="container">
            <TitleBar title="Renk Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-3" label="ID">
                    <b-input v-model="colors.filters.id"></b-input>
                </b-field>
                <b-field class="column is-9" label="Açıklama">
                    <b-input v-model="colors.filters.name"></b-input>
                </b-field>
                <b-field class="column is-4" label="CIE L*">
                    <b-numberinput
                        type="is-link is-light"
                        placeholder="0.00"
                        controls-position="compact"
                        controls-alignment="right"
                        v-model="colors.filters.ciel"
                        :min-step="0.001"
                        expanded
                    ></b-numberinput>
                </b-field>
                <b-field class="column is-4" label="CIE a*">
                    <b-numberinput
                        type="is-link is-light"
                        placeholder="0.00"
                        controls-position="compact"
                        controls-alignment="right"
                        v-model="colors.filters.ciea"
                        :min-step="0.001"
                        expanded
                    ></b-numberinput>
                </b-field>
                <b-field class="column is-4" label="CIE b*">
                    <b-numberinput
                        type="is-link is-light"
                        placeholder="0.00"
                        controls-position="compact"
                        controls-alignment="right"
                        v-model="colors.filters.cieb"
                        :min-step="0.001"
                        expanded
                    ></b-numberinput>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Renk Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="colors_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="colors_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al" @click="colors_ref" disabled />
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!colors.selected.id" @click="colors_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Duzenle" :disabled="!colors.selected.id" @click="colors_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!colors.selected.id" @click="colors_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="colors_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="colors_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :total="colors.total"
                    :data="colors.data"
                    :mobile-cards="false"
                    :current-page.sync="colors.page"
                    :selected.sync="colors.selected"
                    :sort-multiple-data.sync="colors.sorts"
                    :loading="$apollo.queries.readColors.loading"
                    @sort="colors_sort"
                    @dblclick="colors_show"
                    @sorting-priority-removed="colors_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    paginated
                    narrowed
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">Renk kaydı bulunamadı.</div>
                    </template>

                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>
                    <b-table-column field="rgb" v-slot="props" label="Önizleme">
                        <div class="tag" :style="'z-index:9;width:100%;background-color:rgb(' + lab2rgb([props.row.ciel, props.row.ciea, props.row.cieb]) + ',9.0) !important'"></div>
                    </b-table-column>
                    <b-table-column field="name" label="Açıklama" v-slot="props" sortable>{{ props.row.name }}</b-table-column>
                    <b-table-column field="ciel" label="CIE L*" v-slot="props" numeric sortable>{{ props.row.ciel }}</b-table-column>
                    <b-table-column field="ciea" label="CIE a*" v-slot="props" numeric sortable>{{ props.row.ciea }}</b-table-column>
                    <b-table-column field="cieb" label="CIE b*" v-slot="props" numeric sortable>{{ props.row.cieb }}</b-table-column>
                    <b-table-column field="delta" label="Delta" v-slot="props" numeric sortable>{{ props.row.delta }}</b-table-column>
                    <b-table-column field="createdat" label="Oluşturma Tarihi" v-slot="props">{{
                        new Date(props.row.createdat).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })
                    }}</b-table-column>
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "ColorTable",
    components: {
        TitleBar
    },
    data: () => ({
        modi: "",
        colors: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {},
            selected: {}
        }
    }),
    mounted() {
        this.colors_read();
    },
    methods: {
        colors_read() {
            this.$apollo.queries.readColors.start();
            this.$apollo.queries.readColors.refetch();
        },
        colors_new() {
            this.$router.push({ path: "/lab/renk/ekle" });
        },
        colors_ref() {
            this.$router.push({
                path: "/lab/renk/ekle",
                query: { id: this.colors.selected.id, modi: "ref" }
            });
        },
        colors_show() {
            this.$router.push({
                path: "/lab/renk/detay",
                query: { id: this.colors.selected.id, modi: "show" }
            });
        },
        colors_edit() {
            this.$router.push({
                path: "/lab/renk/detay",
                query: { id: this.colors.selected.id, modi: "edit" }
            });
        },
        colors_delete() {},
        colors_print() {},
        colors_export() {},
        colors_sort(field, order) {
            this.colors_nosort(field);
            this.colors.sorts.push({ field, order });
        },
        colors_nosort(field) {
            this.colors.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        },
        lab2rgb(lab) {
            var y = (lab[0] + 16) / 116,
                x = lab[1] / 500 + y,
                z = y - lab[2] / 200,
                r,
                g,
                b;

            x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
            y = 1.0 * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787);
            z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

            r = x * 3.2406 + y * -1.5372 + z * -0.4986;
            g = x * -0.9689 + y * 1.8758 + z * 0.0415;
            b = x * 0.0557 + y * -0.204 + z * 1.057;

            r = r > 0.0031308 ? 1.055 * Math.pow(r, 1 / 2.4) - 0.055 : 12.92 * r;
            g = g > 0.0031308 ? 1.055 * Math.pow(g, 1 / 2.4) - 0.055 : 12.92 * g;
            b = b > 0.0031308 ? 1.055 * Math.pow(b, 1 / 2.4) - 0.055 : 12.92 * b;

            return [parseInt(Math.max(0, Math.min(1, r)) * 255), parseInt(Math.max(0, Math.min(1, g)) * 255), parseInt(Math.max(0, Math.min(1, b)) * 255)];
        }
    },
    apollo: {
        readColors: {
            query: gql`
                query readColors($config: String) {
                    readColors(config: $config) {
                        id
                        name
                        ciel
                        ciea
                        cieb
                        createdat
                        delta
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.colors.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.colors.filters) if (!this.colors.filters[filter]) delete this.colors.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.colors.take,
                        filters: this.colors.filters,
                        skip: (this.colors.page - 1) * this.colors.take
                    })
                };
            },
            update(data) {
                this.colors.data = data.readColors;
            }
        }
    }
};
</script>
